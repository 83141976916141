@layer modules, ui, base;
@layer ui {
  .sk-CardContainer_container__PNt2O {
  container-name: --sk-card;
  container-type: inline-size;
}

}
@layer ui {
  .sk-Card_main__0BVRy {
  --sk-Card-orientation: column;
  --sk-CardCover-margin-x: calc(var(--sk-data-display-card-cover-margin-left) + var(--sk-data-display-card-cover-margin-right));
  --sk-CardCover-margin-y: calc(var(--sk-data-display-card-cover-margin-top) + var(--sk-data-display-card-cover-margin-bottom));
  --sk-CardCover-min-height: unset;

  display: flex;
  box-sizing: border-box;
  flex-direction: var(--sk-Card-orientation);
  width: 100%;
  overflow: hidden;
  border-width: var(--sk-data-display-card-size-border-width);
  border-style: solid;
  border-radius: var(--sk-data-display-card-radius);
  font-family: var(--sk-font-families-poppins);
}

/**
 * Override link style inside card.
 */
.sk-Card_main__0BVRy > [href] {
  outline-offset: calc(var(--sk-data-display-card-size-border-width) * -1);
  color: inherit;
  text-decoration: none;
}

/* Fix alignment and margin when Footer is inside Body */
.sk-Card_main__0BVRy .sk-CardBody > .sk-CardFooter {
  align-items: center;
  margin: auto 0 0;
}

/**
 * Orientation: vertical.
 */
.sk-Card_main--vertical__MyYrK {
  min-width: var(--sk-data-display-card-size-min-width-vertical);
}

/**
 * Orientation: horizontal.
 */
.sk-Card_main--horizontal__sDdRD {
  --sk-Card-orientation: row;
  --sk-CardCover-min-height: auto;
  --sk-CardCover-height: unset;

  min-width: var(--sk-data-display-card-size-min-width-horizontal);
}

.sk-Card_main--horizontal__sDdRD .sk-CardBody + .sk-CardFooter {
  flex-basis: fit-content;
  flex-flow: column wrap;
  flex-shrink: 0;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 0;
}

/**
 * Responsive vertical orientation when container between 240 & 447px
 */

@container --sk-card (min-width: 240px) {
  .sk-Card_main--responsive__lt2R0 {
    min-width: var(--sk-data-display-card-size-min-width-vertical);
  }
}

/**
 * Responsive horizontal orientation when container >= 448px
 */

@container --sk-card (min-width: 448px) {
  .sk-Card_main--responsive__lt2R0 {
    --sk-Card-orientation: row;

    min-width: var(--sk-data-display-card-size-min-width-horizontal);
  }

  .sk-Card_main--responsive__lt2R0 .sk-CardBody + .sk-CardFooter {
    flex-basis: fit-content;
    flex-flow: column wrap;
    flex-shrink: 0;
    align-items: flex-end;
    justify-content: space-between;
    margin-left: 0;
  }
}

/**
 * onBackground style.
 */
.sk-Card_main--light__GD6gW {
  border-color: var(--sk-data-display-card-color-border-default-light);
  background-color: var(--sk-data-display-card-color-background-default-light);
  color: var(--sk-data-display-card-color-default-light);
}

.sk-Card_main--dark__EwWC_ {
  border-color: var(--sk-data-display-card-color-border-default-dark);
  background-color: var(--sk-data-display-card-color-background-default-dark);
  color: var(--sk-data-display-card-color-default-dark);
}

}
@layer ui {
  .sk-CardBody_main__MOweR {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: var(--sk-data-display-card-body-margin-top) var(--sk-data-display-card-body-margin-right) var(--sk-data-display-card-body-margin-bottom) var(--sk-data-display-card-body-margin-left);
  gap: var(--sk-data-display-card-body-gap);
}

}
@layer ui {
  .sk-CardBodyContent_main__5L1zn {
  display: flex;
  flex-direction: column;
  gap: var(--sk-data-display-card-body-content-gap);
  flex-grow: 1;
}

}
@layer ui {
  .sk-CardBodyHeader_main__9_8dW {
  display: flex;
  flex-direction: column;
  gap: var(--sk-data-display-card-body-header-gap);
}

}
@layer ui {
  .sk-Link_main__nOUpr {
  --Link-external-icon-caption1-size: var(--sk-size-12);
  --Link-external-icon-body2-size: var(--sk-size-16);
  --Link-external-icon-body1-size: var(--sk-size-20);
  --Link-external-icon-size: var(--Link-external-icon-body2-size);
  --Link-external-icon-data: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Im0xNSA1Yy0uNTUyMyAwLTEtLjQ0NzcyLTEtMXMuNDQ3Ny0xIDEtMWg1Yy41NTIzIDAgMSAuNDQ3NzIgMSAxdjVjMCAuNTUyMjgtLjQ0NzcgMS0xIDFzLTEtLjQ0NzcyLTEtMXYtMi41ODU3OWwtOC4yOTI5IDguMjkyODljLS4zOTA1LjM5MDUtMS4wMjM2OC4zOTA1LTEuNDE0MjEgMC0uMzkwNTItLjM5MDUtLjM5MDUyLTEuMDIzNyAwLTEuNDE0Mmw4LjI5MjkxLTguMjkyOXptLTExLjEyMTMyIDEuODc4NjhjLjU2MjYxLS41NjI2MSAxLjMyNTY3LS44Nzg2OCAyLjEyMTMyLS44Nzg2OGg1Yy41NTIzIDAgMSAuNDQ3NzIgMSAxcy0uNDQ3NyAxLTEgMWgtNWMtLjI2NTIyIDAtLjUxOTU3LjEwNTM2LS43MDcxMS4yOTI4OS0uMTg3NTMuMTg3NTQtLjI5Mjg5LjQ0MTg5LS4yOTI4OS43MDcxMXY5YzAgLjI2NTIuMTA1MzYuNTE5Ni4yOTI4OS43MDcxLjE4NzU0LjE4NzUuNDQxODkuMjkyOS43MDcxMS4yOTI5aDljLjI2NTIgMCAuNTE5Ni0uMTA1NC43MDcxLS4yOTI5cy4yOTI5LS40NDE5LjI5MjktLjcwNzF2LTVjMC0uNTUyMy40NDc3LTEgMS0xczEgLjQ0NzcgMSAxdjVjMCAuNzk1Ny0uMzE2MSAxLjU1ODctLjg3ODcgMi4xMjEzcy0xLjMyNTYuODc4Ny0yLjEyMTMuODc4N2gtOWMtLjc5NTY1IDAtMS41NTg3MS0uMzE2MS0yLjEyMTMyLS44Nzg3cy0uODc4NjgtMS4zMjU3LS44Nzg2OC0yLjEyMTN2LTljMC0uNzk1NjUuMzE2MDctMS41NTg3MS44Nzg2OC0yLjEyMTMyeiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");

  display: inline;
  /* This property is required when Link is used inside flex parent. */
  width: -moz-fit-content;
  width: fit-content;
  border-radius: var(--sk-navigation-link-radius);
  outline: none;
  font-family: var(--sk-typography-navigation-link-text-typography-m-font-family), helvetica, arial, sans-serif;
  font-size: inherit;
  font-weight: var(--sk-typography-navigation-link-text-typography-m-font-weight);
  line-height: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.sk-Link_main--external__hOR_4::after {
  content: "";
  display: inline-flex;
  width: var(--Link-external-icon-size);
  height: var(--Link-external-icon-size);
  margin-left: var(--sk-space-2);
  /* Adjust svg alignment with text */
  transform: translateY(-0.0625rem); /* -1px */
  /* Use the same color of the link for the external icon. */
  background-color: currentcolor;
  vertical-align: sub;
  /* Required by Sandpack as it uses css as is. */
  -webkit-mask-image: var(--Link-external-icon-data);
  mask-image: var(--Link-external-icon-data);
  -webkit-mask-size: cover;
  mask-size: cover;
}

.sk-Link_main__nOUpr:focus-visible {
  outline-offset: 0;
  outline-style: solid;
  outline-width: var(--sk-navigation-link-size-border-focus);
}

.sk-Link_main--caption1__YoyQD {
  font-size: var(--sk-typography-navigation-link-text-typography-s-font-size);
  font-weight: var(--sk-typography-navigation-link-text-typography-s-font-weight);
  line-height: var(--sk-typography-navigation-link-text-typography-s-line-height);
}

.sk-Link_main--caption1__YoyQD.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-caption1-size);

  line-height: var(--sk-typography-navigation-link-text-typography-s-line-height);
}

.sk-Link_main--body1__4qy4x {
  font-size: var(--sk-typography-navigation-link-text-typography-l-font-size);
  line-height: var(--sk-typography-navigation-link-text-typography-l-line-height);
}

.sk-Link_main--body1__4qy4x.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-body1-size);

  line-height: var(--sk-typography-navigation-link-text-typography-l-line-height);
}

.sk-Link_main--body2__5CxGA {
  font-size: var(--sk-typography-navigation-link-text-typography-m-font-size);
  line-height: var(--sk-typography-navigation-link-text-typography-m-line-height);
}

.sk-Link_main--body2__5CxGA.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-body2-size);

  line-height: var(--sk-typography-navigation-link-text-typography-m-line-height);
}

.sk-Link_main--light__sHPm0 {
  color: var(--sk-navigation-link-color-default-light);
}

.sk-Link_main--light__sHPm0:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-light);
}

.sk-Link_main--light__sHPm0:hover {
  background-color: var(--sk-navigation-link-color-background-hover-light);
  color: var(--sk-navigation-link-color-hover-light);
}

.sk-Link_main--dark__PTzF2 {
  color: var(--sk-navigation-link-color-default-dark);
}

.sk-Link_main--dark__PTzF2:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-dark);
}

.sk-Link_main--dark__PTzF2:hover {
  background-color: var(--sk-navigation-link-color-background-hover-dark);
  color: var(--sk-navigation-link-color-hover-dark);
}

}
@layer ui {
  .sk-CardCover_main__mcHXh {
  --sk-CardCover-ratio: 1;

  position: relative;
  z-index: 0;
  box-sizing: border-box;
  width: calc(100% - var(--sk-data-display-card-cover-margin-right) - var(--sk-data-display-card-cover-margin-left));
  height: auto;
  margin: var(--sk-data-display-card-cover-margin-top) var(--sk-data-display-card-cover-margin-right) var(--sk-data-display-card-cover-margin-bottom) var(--sk-data-display-card-cover-margin-left);
  padding: var(--sk-data-display-card-cover-padding);
  overflow: hidden;
  aspect-ratio: var(--sk-CardCover-ratio);
}

.sk-CardCover_main--fullWidth__ChWFx {
  --sk-data-display-card-cover-radius: 0;

  width: 100%;
  margin: 0;
}

.sk-CardCover_main__mcHXh > *:not(.sk-CardCoverImage) {
  position: relative;
  z-index: 2;
  max-width: 100%;
}

}
@layer ui {
  .sk-CardCoverImage_main__sXQ31 {
  position: absolute;
  z-index: 1;
  inset: 0;
  pointer-events: none;
  aspect-ratio: var(--sk-CardCover-ratio);
}

.sk-CardCoverImage_main__sXQ31 > img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  border-radius: var(--sk-data-display-card-cover-radius);
}

}
@layer ui {
  .sk-InputWrapper_main__1zVBN {
  display: flex;
  flex-direction: column;
  font-family: var(--sk-font-families-poppins);
}

.sk-InputWrapper_labelWrapper__RFcXM {
  display: flex;
  gap: var(--sk-space-4);
  align-items: center;
  margin-bottom: var(--sk-space-8);
}

.sk-InputWrapper_label__fhRxj {
  font-family: var(--sk-typography-body-2-semibold-font-family);
  font-size: var(--sk-typography-body-2-semibold-font-size);
  font-weight: var(--sk-typography-body-2-semibold-font-weight);
  line-height: var(--sk-typography-body-2-semibold-line-height);
}

.sk-InputWrapper_subLabel___4Fiu {
  font-family: var(--sk-typography-caption-1-font-family);
  font-size: var(--sk-typography-caption-1-font-size);
  font-weight: var(--sk-typography-caption-1-font-weight);
  line-height: var(--sk-typography-caption-1-line-height);
}

.sk-InputWrapper_messageWrapper__BsKEb {
  display: flex;
  justify-content: space-between;
  margin-top: var(--sk-space-8);
  gap: var(--sk-space-8);
}

.sk-InputWrapper_message__4RFam,
.sk-InputWrapper_secondaryMessage__UwXuM {
  font-family: var(--sk-typography-caption-1-font-family);
  font-size: var(--sk-typography-caption-1-font-size);
  font-weight: var(--sk-typography-caption-1-font-weight);
  line-height: var(--sk-typography-caption-1-line-height);
}

.sk-InputWrapper_secondaryMessage__UwXuM {
  margin-left: auto;
}

}
@layer ui {
  .sk-Divider_main__KCaPd {
  --Divider-size: calc(var(--sk-size-2) / 2);

  display: inline-flex;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

.sk-Divider_main--horizontal__3iUOd {
  flex: 0 1 auto;
  justify-content: center;
  width: 100%;
  height: var(--Divider-size);
}

.sk-Divider_main--vertical__F_EbS {
  flex: 0 0 var(--Divider-size);
  align-self: stretch;
  justify-content: center;
  width: var(--Divider-size);
  min-height: 100%;
}

.sk-Divider_main--light__2ofRn {
  background-color: var(--sk-color-dark-20);
}

.sk-Divider_main--dark__DBhlj {
  background-color: var(--sk-color-light-30);
}

}
@layer ui {
  .sk-CardFooter_main__j47mA {
  display: flex;
  gap: var(--sk-data-display-card-footer-gap);
  margin: var(--sk-data-display-card-footer-margin-top)  var(--sk-data-display-card-footer-margin-right)  var(--sk-data-display-card-footer-margin-bottom)  var(--sk-data-display-card-footer-margin-left);
}

}
@layer ui {
  .sk-Checkbox_container__iYz32 {
  position: relative;
}

.sk-Checkbox_main__eUdvn {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  gap: var(--sk-space-8);
  padding: var(--sk-space-12);
  transition: background-color 100ms ease-out;
  border-radius: var(--sk-form-checkbox-radius);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-Checkbox_hiddenCheckboxInput__0NUuq {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
}

.sk-Checkbox_checkbox__y5PLh {
  display: inline-flex;
  box-sizing: border-box;
  flex-shrink: 0;
  align-self: center;
  width: var(--sk-size-20);
  height: var(--sk-size-20);
  margin: var(--sk-space-2) 0;
  border: var(--sk-size-2) solid;
  border-radius: var(--sk-form-checkbox-input-radius);
  place-items: center center;
}

.sk-Checkbox_label__S3CpC {
  font-family: var(--sk-typography-form-checkbox-typography-text-m-font-family);
  font-size: var(--sk-typography-form-checkbox-typography-text-m-font-size);
  font-weight: var(--sk-typography-form-checkbox-typography-text-m-font-weight);
  line-height: var(--sk-typography-form-checkbox-typography-text-m-line-height);
}

.sk-Checkbox_labelWrapper--dropdown-item__TMFtl {
  display: flex;
  align-items: center;
  min-width: 0;
  gap: var(--sk-space-4);
}

.sk-Checkbox_label--dropdown-item__5FX5b {
  display: -webkit-box;
  overflow: hidden;
  color: var(--sk-color-black);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_label--dropdown-item__5FX5b {
  font-family: var(--sk-typography-body-2-semibold-font-family);
  font-size: var(--sk-typography-body-2-semibold-font-size);
  font-weight: var(--sk-typography-body-2-semibold-font-weight);
  line-height: var(--sk-typography-body-2-semibold-line-height);
}

.sk-Checkbox_secondaryLabel__jzvUl {
  margin-left: var(--sk-space-8);
  color: var(--sk-color-grey-600);
  font-family: var(--sk-typography-form-checkbox-typography-text-m-font-family);
  font-size: var(--sk-typography-form-checkbox-typography-text-m-font-size);
  font-weight: var(--sk-typography-form-checkbox-typography-text-m-font-weight);
  line-height: var(--sk-typography-form-checkbox-typography-text-m-line-height);
}

.sk-Checkbox_secondaryLabel--dropdown-item__6oeMp {
  overflow: hidden;
  color: var(--sk-color-grey-600);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-Checkbox_errorMessage___j55S {
  padding: var(--sk-space-2) var(--sk-space-12);
  color: var(--sk-color-error-500);
}

.sk-Checkbox_checkedState__IoFPY {
  flex-shrink: 0;
}

.sk-Checkbox_main--disabled__Tug8s {
  cursor: not-allowed;
}

.sk-Checkbox_main--light__bacP3 {
  color: var(--sk-form-checkbox-color-default-light);
}

.sk-Checkbox_main--dark__rFl_O {
  color: var(--sk-form-checkbox-color-default-dark);
}

.sk-Checkbox_main--light__bacP3:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) {
  background-color: var(--sk-form-checkbox-color-background-hover-light);
  color: var(--sk-form-checkbox-color-hover-light);
}

.sk-Checkbox_main--dark__rFl_O:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) {
  background-color: var(--sk-form-checkbox-color-background-hover-dark);
  color: var(--sk-form-checkbox-color-hover-dark);
}

input:focus-visible + .sk-Checkbox_main__eUdvn {
  outline: none;
  box-shadow: var(--sk-shadow-focus-ring-light-shadow-3), var(--sk-shadow-focus-ring-light-shadow-2),
    var(--sk-shadow-focus-ring-light-shadow-1);
}

input:focus-visible + .sk-Checkbox_main--light__bacP3 {
  background-color: var(--sk-form-checkbox-color-background-focus-light);
  color: var(--sk-form-checkbox-color-focus-light);
}

input:focus-visible + .sk-Checkbox_main--dark__rFl_O {
  background-color: var(--sk-form-checkbox-color-background-focus-dark);
  color: var(--sk-form-checkbox-color-focus-dark);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--disabled__Tug8s {
  color: var(--sk-form-checkbox-color-disabled-light);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--disabled__Tug8s {
  color: var(--sk-form-checkbox-color-disabled-dark);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--checked__6uyhF.sk-Checkbox_main--disabled__Tug8s,
.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--indeterminate___tYpt.sk-Checkbox_main--disabled__Tug8s {
  color: var(--sk-form-checkbox-color-disabled-light);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--checked__6uyhF.sk-Checkbox_main--disabled__Tug8s,
.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt.sk-Checkbox_main--disabled__Tug8s {
  color: var(--sk-form-checkbox-color-disabled-dark);
}

.sk-Checkbox_main--light__bacP3:hover:not(.sk-Checkbox_main--disabled__Tug8s) .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-hover-light);
  color: var(--sk-form-checkbox-input-color-hover-light);
}

.sk-Checkbox_main--light__bacP3:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) + .sk-Checkbox_main--light__bacP3.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--light__bacP3:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) + .sk-Checkbox_main--light__bacP3.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-hover-light);
}

.sk-Checkbox_main--dark__rFl_O:hover:not(.sk-Checkbox_main--disabled__Tug8s) .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-hover-dark);
  color: var(--sk-form-checkbox-input-color-hover-dark);
}

.sk-Checkbox_main--dark__rFl_O:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) + .sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--dark__rFl_O:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) + .sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-hover-dark);
}

input:focus-visible + .sk-Checkbox_main--light__bacP3 .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-focus-light);
  color: var(--sk-form-checkbox-input-color-focus-light);
}

input:focus-visible + .sk-Checkbox_main--light__bacP3.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
input:focus-visible + .sk-Checkbox_main--light__bacP3.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-focus-light);
}

input:focus-visible + .sk-Checkbox_main--dark__rFl_O .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-focus-dark);
  color: var(--sk-form-checkbox-input-color-focus-dark);
}

input:focus-visible + .sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
input:focus-visible + .sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-focus-dark);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-disabled-light);
  color: var(--sk-form-checkbox-input-color-disabled-light);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--checked__6uyhF.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--indeterminate___tYpt.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-disabled-light);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-disabled-dark);
  color: var(--sk-form-checkbox-input-color-disabled-dark);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--checked__6uyhF.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-disabled-dark);
}

.sk-Checkbox_main--light__bacP3 .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-default-light);
}

.sk-Checkbox_main--dark__rFl_O .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-default-dark);
}

.sk-Checkbox_main--light__bacP3:hover:not(.sk-Checkbox_main--disabled__Tug8s) .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-hover-light);
}

.sk-Checkbox_main--dark__rFl_O:hover:not(.sk-Checkbox_main--disabled__Tug8s) .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-hover-dark);
}

input:focus-visible + .sk-Checkbox_main--light__bacP3 .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-focus-light);
}

input:focus-visible + .sk-Checkbox_main--dark__rFl_O .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-focus-dark);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-disabled-light);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-disabled-dark);
}

.sk-Checkbox_main--light__bacP3 .sk-Checkbox_checkedState__IoFPY {
  color: var(--sk-form-checkbox-input-color-default-light);
}

.sk-Checkbox_main--dark__rFl_O .sk-Checkbox_checkedState__IoFPY {
  color: var(--sk-form-checkbox-input-color-default-dark);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-default-light);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-default-dark);
}

}
@layer ui {
  .sk-Badge_main__AVFxz {
  --sk-Badge-y-padding: var(--sk-space-4);
  --sk-Badge-x-padding: var(--sk-space-8);
  --sk-Badge-label-spacing: var(--sk-space-4);
  --sk-Badge-height: calc(var(--sk-Badge-y-padding) * 2 + var(--sk-typography-data-display-badge-text-typography-m-line-height));

  display: inline-flex;
  box-sizing: border-box;
  flex-grow: 0;
  align-items: center;
  align-self: end;
  justify-content: center;
  min-width: 0; /* Required for overflow: ellipsis; */
  height: var(--sk-Badge-height);
  padding: var(--sk-Badge-y-padding) calc(var(--sk-Badge-x-padding) + var(--sk-Badge-label-spacing));
  overflow: hidden;
  border-radius: var(--sk-radius-20);
  font-family: var(--sk-typography-data-display-badge-text-typography-m-font-family);
  font-weight: var(--sk-typography-data-display-badge-text-typography-m-font-weight);
  gap: var(--sk-Badge-label-spacing);
}

.sk-Badge_label__f1crk {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.sk-Badge_icon__M8ilK {
  flex-shrink: 0;
}

.sk-Badge_main--withIcon__uFfRO {
  padding-right: calc(var(--sk-Badge-x-padding) + var(--sk-Badge-label-spacing));
  padding-left: var(--sk-Badge-x-padding);
}

.sk-Badge_main--medium__d1Pq_ {
  font-size: var(--sk-typography-data-display-badge-text-typography-m-font-size);
  font-weight: var(--sk-typography-data-display-badge-text-typography-m-font-weight);
  line-height: var(--sk-typography-data-display-badge-text-typography-m-line-height);
}

.sk-Badge_main--large__WUCcE {
  --sk-Badge-height: calc(var(--sk-Badge-y-padding) * 2 + var(--sk-typography-data-display-badge-text-typography-l-line-height));

  font-size: var(--sk-typography-data-display-badge-text-typography-l-font-size);
  font-weight: var(--sk-typography-data-display-badge-text-typography-l-font-weight);
  line-height: var(--sk-typography-data-display-badge-text-typography-l-line-height);
}

.sk-Badge_main--tertiary__J_3Du {
  border-width: var(--sk-data-display-badge-size-border-tertiary);
  border-style: solid;
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--primary__yAKxY {
  background-color: var(--sk-data-display-badge-color-background-primary-light);
  color: var(--sk-data-display-badge-color-primary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--primary__yAKxY.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-primary-faded-light);
  color: var(--sk-data-display-badge-color-primary-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--secondary__wgMjW {
  background-color: var(--sk-data-display-badge-color-background-secondary-light);
  color: var(--sk-data-display-badge-color-secondary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--secondary__wgMjW.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-secondary-faded-light);
  color: var(--sk-data-display-badge-color-secondary-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--tertiary__J_3Du {
  border-color: var(--sk-data-display-badge-color-border-tertiary-light);
  background-color: var(--sk-data-display-badge-color-background-tertiary-light);
  color: var(--sk-data-display-badge-color-tertiary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--success__3Jnl2 {
  background-color: var(--sk-data-display-badge-color-background-success-light);
  color: var(--sk-data-display-badge-color-success-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--success__3Jnl2.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-success-faded-light);
  color: var(--sk-data-display-badge-color-success-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--warning__x2jJg {
  background-color: var(--sk-data-display-badge-color-background-warning-light);
  color: var(--sk-data-display-badge-color-warning-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--warning__x2jJg.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-warning-faded-light);
  color: var(--sk-data-display-badge-color-warning-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--error__X5oen {
  background-color: var(--sk-data-display-badge-color-background-error-light);
  color: var(--sk-data-display-badge-color-error-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--error__X5oen.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-error-faded-light);
  color: var(--sk-data-display-badge-color-error-faded-light);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--primary__yAKxY {
  background-color: var(--sk-data-display-badge-color-background-primary-dark);
  color: var(--sk-data-display-badge-color-primary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--primary__yAKxY.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-primary-faded-dark);
  color: var(--sk-data-display-badge-color-primary-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--secondary__wgMjW {
  background-color: var(--sk-data-display-badge-color-background-secondary-dark);
  color: var(--sk-data-display-badge-color-secondary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--secondary__wgMjW.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-secondary-faded-dark);
  color: var(--sk-data-display-badge-color-secondary-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--tertiary__J_3Du {
  border-color: var(--sk-data-display-badge-color-border-tertiary-dark);
  background-color: var(--sk-data-display-badge-color-background-tertiary-dark);
  color: var(--sk-data-display-badge-color-tertiary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--success__3Jnl2 {
  background-color: var(--sk-data-display-badge-color-background-success-dark);
  color: var(--sk-data-display-badge-color-success-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--success__3Jnl2.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-success-faded-dark);
  color: var(--sk-data-display-badge-color-success-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--warning__x2jJg {
  background-color: var(--sk-data-display-badge-color-background-warning-dark);
  color: var(--sk-data-display-badge-color-warning-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--warning__x2jJg.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-warning-faded-dark);
  color: var(--sk-data-display-badge-color-warning-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--error__X5oen {
  background-color: var(--sk-data-display-badge-color-background-error-dark);
  color: var(--sk-data-display-badge-color-error-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--error__X5oen.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-error-faded-dark);
  color: var(--sk-data-display-badge-color-error-faded-dark);
}

}
@layer ui {
  .sk-Signature_main__ihSMF {
  display: inline-flex;
  gap: var(--sk-space-16);
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}

.sk-Signature_avatar__xelcE {
  flex-shrink: 0;
}

.sk-Signature_author__gQyZL > .sk-Text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.sk-Signature_main--light__McguI {
  color: var(--sk-color-black);
}

.sk-Signature_main--dark__s6y9D {
  color: var(--sk-color-white);
}

}
@layer ui {
  .sk-Button_main__8xbMi {
  --sk-Button-y-padding: var(--sk-space-12);
  --sk-Button-x-small-padding: var(--sk-space-12);
  --sk-Button-x-padding: var(--sk-space-16);
  --sk-Button-text-spacing: var(--sk-space-4);
  --sk-Button-icon-gap: var(--sk-space-4);

  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding-top: var(--sk-Button-y-padding);
  padding-bottom: var(--sk-Button-y-padding);
  transition: background-color 100ms ease-out;
  border: none;
  outline: none;
  font-family: var(--sk-typography-action-button-text-typography-m-font-family);
  text-decoration: none;
  cursor: pointer;
  gap: calc(var(--sk-Button-text-spacing) + var(--sk-Button-icon-gap));
}

.sk-Button_icon__MfDio {
  flex-shrink: 0;
}

.sk-Button_main--loading__Bvp4c {
  color: transparent;
  cursor: progress;
}

.sk-Button_main--loading__Bvp4c .sk-Button_loadingIcon__xSPkR {
  color: initial;
}

.sk-Button_loadingIcon__xSPkR {
  position: absolute;
  inset: 0;
  margin: auto;
  animation: sk-Button_spin__0T9Rc 1.5s linear infinite;
}

@keyframes sk-Button_spin__0T9Rc {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.sk-Button_main--disabled__3bTdp {
  cursor: not-allowed;
}

/* Sizes */
.sk-Button_main--small__I4ZXK {
  padding-right: calc(var(--sk-Button-x-small-padding) + var(--sk-Button-text-spacing));
  padding-left: calc(var(--sk-Button-x-small-padding) + var(--sk-Button-text-spacing));
  border-radius: var(--sk-action-button-radius-s);
  font-size: var(--sk-typography-action-button-text-typography-s-font-size);
  font-weight: var(--sk-typography-action-button-text-typography-s-font-weight);
  line-height: var(--sk-typography-action-button-text-typography-s-line-height);
}

.sk-Button_main--medium__vyUkg {
  border-radius: var(--sk-action-button-radius-m);
  font-size: var(--sk-typography-action-button-text-typography-m-font-size);
  font-weight: var(--sk-typography-action-button-text-typography-m-font-weight);
  line-height: var(--sk-typography-action-button-text-typography-m-line-height);
}

.sk-Button_main--large__g_Uug {
  border-radius: var(--sk-action-button-radius-l);
  font-size: var(--sk-typography-action-button-text-typography-l-font-size);
  font-weight: var(--sk-typography-action-button-text-typography-l-font-weight);
  line-height: var(--sk-typography-action-button-text-typography-l-line-height);
}

/* Spacing */
.sk-Button_main--medium__vyUkg,
.sk-Button_main--large__g_Uug {
  padding-right: calc(var(--sk-Button-x-padding) + var(--sk-Button-text-spacing));
  padding-left: calc(var(--sk-Button-x-padding) + var(--sk-Button-text-spacing));
}

.sk-Button_main--small__I4ZXK.sk-Button_main--withIcon__U76B5 {
  padding-right: calc(var(--sk-Button-x-small-padding) + var(--sk-Button-text-spacing));
  padding-left: var(--sk-Button-x-small-padding);
}

.sk-Button_main--medium__vyUkg.sk-Button_main--withIcon__U76B5,
.sk-Button_main--large__g_Uug.sk-Button_main--withIcon__U76B5 {
  padding-right: calc(var(--sk-Button-x-padding) + var(--sk-Button-text-spacing));
  padding-left: var(--sk-Button-x-padding);
}

/* Variants & Background */
/* On background light */
.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR:not(.sk-Button_main--loading__Bvp4c) {
  background-color: var(--sk-action-button-color-background-primary-default-light);
  color: var(--sk-action-button-color-primary-default-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-primary-hover-light);
  color: var(--sk-action-button-color-primary-hover-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-primary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  color: var(--sk-action-button-color-primary-focus-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-primary-active-light);
  color: var(--sk-action-button-color-primary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-primary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR.sk-Button_main--disabled__3bTdp {
  background-color: var(--sk-action-color-background-primary-disabled-light);
  color: var(--sk-action-button-color-primary-disabled-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM {
  outline: 2px solid var(--sk-action-button-color-border-secondary-default-light);
  outline-offset: -2px;
  background-color: transparent;
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--loading__Bvp4c) {
  color: var(--sk-action-button-color-secondary-default-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-secondary-hover-light);
  color: var(--sk-action-button-color-secondary-hover-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-secondary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  color: var(--sk-action-button-color-secondary-focus-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-secondary-active-light);
  color: var(--sk-action-button-color-secondary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-secondary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM.sk-Button_main--disabled__3bTdp {
  outline: 2px solid var(--sk-action-button-color-border-secondary-disabled-light);
  outline-offset: -2px;
  color: var(--sk-action-button-color-secondary-disabled-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--loading__Bvp4c) {
  background-color: transparent;
  color: var(--sk-action-button-color-tertiary-default-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-tertiary-hover-light);
  color: var(--sk-action-button-color-tertiary-hover-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-tertiary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  color: var(--sk-action-button-color-tertiary-focus-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-tertiary-active-light);
  color: var(--sk-action-button-color-tertiary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-tertiary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc.sk-Button_main--disabled__3bTdp {
  color: var(--sk-action-button-color-tertiary-disabled-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt:not(.sk-Button_main--loading__Bvp4c) {
  background-color: var(--sk-action-button-color-background-highlight-default);
  color: var(--sk-action-button-color-highlight-default);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-highlight-hover);
  color: var(--sk-action-button-color-highlight-hover);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-highlight-focus);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  color: var(--sk-action-button-color-highlight-focus);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-highlight-active);
  color: var(--sk-action-button-color-highlight-active);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-highlight-active);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt.sk-Button_main--disabled__3bTdp {
  background-color: var(--sk-action-button-color-background-highlight-disabled);
  color: var(--sk-action-button-color-highlight-disabled);
}

/* On background dark */
.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR {
  background-color: var(--sk-action-button-color-background-primary-default-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR:not(.sk-Button_main--loading__Bvp4c) {
  color: var(--sk-action-button-color-primary-default-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-primary-hover-dark);
  color: var(--sk-action-button-color-primary-hover-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-primary-focus-dark);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
  color: var(--sk-action-button-color-primary-focus-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-primary-active-dark);
  color: var(--sk-action-button-color-primary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-primary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR.sk-Button_main--disabled__3bTdp {
  background-color: var(--sk-action-color-background-primary-disabled-dark);
  color: var(--sk-action-button-color-primary-disabled-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM {
  outline: 2px solid var(--sk-action-button-color-border-secondary-default-dark);
  outline-offset: -2px;
  background-color: transparent;
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--loading__Bvp4c) {
  color: var(--sk-action-button-color-secondary-default-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-secondary-hover-dark);
  color: var(--sk-action-button-color-secondary-hover-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-secondary-focus-dark);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
  color: var(--sk-action-button-color-secondary-focus-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-secondary-active-dark);
  color: var(--sk-action-button-color-secondary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-secondary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM.sk-Button_main--disabled__3bTdp {
  outline: 2px solid var(--sk-action-button-color-border-secondary-disabled-dark);
  outline-offset: -2px;
  color: var(--sk-action-button-color-secondary-disabled-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--loading__Bvp4c) {
  background-color: transparent;
  color: var(--sk-action-button-color-tertiary-default-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-tertiary-hover-dark);
  color: var(--sk-action-button-color-tertiary-hover-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-tertiary-focus-dark);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
  color: var(--sk-action-button-color-tertiary-focus-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-tertiary-active-dark);
  color: var(--sk-action-button-color-tertiary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-tertiary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc.sk-Button_main--disabled__3bTdp {
  color: var(--sk-action-button-color-tertiary-disabled-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt:not(.sk-Button_main--loading__Bvp4c) {
  background-color: var(--sk-action-button-color-background-highlight-default);
  color: var(--sk-action-button-color-highlight-default);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-highlight-hover);
  color: var(--sk-action-button-color-highlight-hover);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-highlight-focus);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
  color: var(--sk-action-button-color-highlight-focus);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  outline: none;
  background-color: var(--sk-action-button-color-background-highlight-active);
  box-shadow: none;
  color: var(--sk-action-button-color-highlight-active);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-highlight-active);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt.sk-Button_main--disabled__3bTdp {
  background-color: var(--sk-action-button-color-background-highlight-disabled);
  color: var(--sk-action-button-color-highlight-disabled);
}

}
@layer ui {
  /** @define Icon */

.Icon_jds-Icon__qGb6e {
  fill: currentcolor;
  vertical-align: middle;
}

.Icon_jds-Icon--tiny__eJMHC {
  width: var(--icon-size-tiny);
  min-width: var(--icon-size-tiny);
  height: var(--icon-size-tiny);
}

.Icon_jds-Icon--small__TQ5up {
  width: var(--icon-size-small);
  min-width: var(--icon-size-small);
  height: var(--icon-size-small);
}

.Icon_jds-Icon--medium__CgyeE {
  width: var(--icon-size-medium);
  min-width: var(--icon-size-medium);
  height: var(--icon-size-medium);
}

.Icon_jds-Icon--big__bzeG_ {
  width: var(--icon-size-big);
  min-width: var(--icon-size-big);
  height: var(--icon-size-big);
}

.Icon_jds-Icon--large__KNPQa {
  width: var(--icon-size-large);
  min-width: var(--icon-size-large);
  height: var(--icon-size-large);
}

.Icon_jds-Icon--huge__GF_BE {
  width: var(--icon-size-huge);
  min-width: var(--icon-size-huge);
  height: var(--icon-size-huge);
}

}
@layer ui {
  .sk-IconButton_main__Kzatv {
  --IconButton-title-vertical-padding: 0.35rem;
  --IconButton-title-font-size: 0.8rem;
  --IconButton-top-position: calc(
    calc(var(--IconButton-title-font-size) + 2 * var(--IconButton-title-vertical-padding)) * -1
  );

  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: var(--sk-space-12);
  overflow: visible;
  transition: background-color 100ms ease-out;
  border: none;
  outline: none;
  cursor: pointer;
}

.sk-IconButton_main__Kzatv:disabled {
  cursor: not-allowed;
}

.sk-IconButton_main__Kzatv:active {
  transition: none;
}

.sk-IconButton_main--extraSmall__h0QpA {
  width: calc(var(--sk-size-24));
  height: calc(var(--sk-size-24));
  padding: var(--sk-space-4);
  border-radius: var(--sk-radius-8);
}

.sk-IconButton_main--small__AogZE {
  width: calc(var(--sk-size-40) + var(--sk-size-4));
  height: calc(var(--sk-size-40) + var(--sk-size-4));
  border-radius: var(--sk-space-8);
}

.sk-IconButton_main--medium__5g9RA {
  width: var(--sk-size-48);
  height: var(--sk-size-48);
  border-radius: var(--sk-space-12);
}

.sk-IconButton_main--outlined__IjORq {
  padding: var(--sk-space-8);
  border-width: var(--sk-border-width-2);
  border-style: solid;
}

/**
 * Variant: transparent on light
 */

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--light__3E_eM {
  background-color: var(--sk-action-icon-button-color-background-primary-default-light);
  color: var(--sk-action-icon-button-color-primary-default-light);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--light__3E_eM:enabled:hover {
  background-color: var(--sk-action-icon-button-color-background-primary-hover-light);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--light__3E_eM:enabled:focus-visible {
  background-color: var(--sk-action-icon-button-color-background-primary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--light__3E_eM:enabled:active {
  background-color: var(--sk-action-icon-button-color-background-primary-active-light);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--light__3E_eM:disabled {
  background-color: var(--sk-action-icon-button-color-background-primary-disabled-light);
  color: var(--sk-action-icon-button-color-primary-disabled-light);
}

/**
 * Variant: filled on light
 */

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--light__3E_eM {
  background-color: var(--sk-action-icon-button-color-background-secondary-default-light);
  color: var(--sk-action-icon-button-color-secondary-default-light);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--light__3E_eM:enabled:hover {
  background-color: var(--sk-action-icon-button-color-background-secondary-hover-light);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--light__3E_eM:enabled:focus-visible {
  background-color: var(--sk-action-icon-button-color-background-secondary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--light__3E_eM:enabled:active {
  background-color: var(--sk-action-icon-button-color-background-secondary-active-light);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--light__3E_eM:disabled {
  background-color: var(--sk-action-icon-button-color-background-secondary-disabled-light);
  color: var(--sk-action-icon-button-color-secondary-disabled-light);
}

/**
 * Variant: outlined on light
 */

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--light__3E_eM {
  border-color: var(--sk-action-icon-button-color-border-tertiary-default-light);
  background-color: var(--sk-action-icon-button-color-background-tertiary-default-light);
  color: var(--sk-action-icon-button-color-tertiary-default-light);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--light__3E_eM:enabled:hover {
  border-color: var(--sk-action-icon-button-color-border-tertiary-hover-light);
  background-color: var(--sk-action-icon-button-color-background-tertiary-hover-light);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--light__3E_eM:enabled:focus-visible {
  background-color: var(--sk-action-icon-button-color-background-tertiary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--light__3E_eM:enabled:active {
  border-color: var(--sk-action-icon-button-color-border-tertiary-active-light);
  background-color: var(--sk-action-icon-button-color-background-tertiary-active-light);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--light__3E_eM:disabled {
  border-color: var(--sk-action-icon-button-color-border-tertiary-disabled-light);
  color: var(--sk-action-icon-button-color-tertiary-disabled-light);
}

/**
 * Variant: transparent on dark
 */

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--dark__DqpiO {
  background-color: var(--sk-action-icon-button-color-background-primary-default-dark);
  color: var(--sk-action-icon-button-color-primary-default-dark);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--dark__DqpiO:enabled:hover {
  background-color: var(--sk-action-icon-button-color-background-primary-hover-dark);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--dark__DqpiO:enabled:focus-visible {
  background-color: var(--sk-action-icon-button-color-background-primary-focus-dark);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--dark__DqpiO:enabled:active {
  background-color: var(--sk-action-icon-button-color-background-primary-active-dark);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--dark__DqpiO:disabled {
  background-color: var(--sk-action-icon-button-color-background-primary-disabled-dark);
  color: var(--sk-action-icon-button-color-primary-disabled-dark);
}

/**
 * Variant: filled on dark
 */

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--dark__DqpiO {
  background-color: var(--sk-action-icon-button-color-background-secondary-default-dark);
  color: var(--sk-action-icon-button-color-secondary-default-dark);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--dark__DqpiO:enabled:hover {
  background-color: var(--sk-action-icon-button-color-background-secondary-hover-dark);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--dark__DqpiO:enabled:focus-visible {
  background-color: var(--sk-action-icon-button-color-background-secondary-focus-dark);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--dark__DqpiO:enabled:active {
  background-color: var(--sk-action-icon-button-color-background-secondary-active-dark);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--dark__DqpiO:disabled {
  background-color: var(--sk-action-icon-button-color-background-secondary-disabled-dark);
  color: var(--sk-action-icon-button-color-secondary-disabled-dark);
}

/**
 * Variant: outlined on dark
 */

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--dark__DqpiO {
  border-color: var(--sk-action-icon-button-color-border-tertiary-default-dark);
  background-color: var(--sk-action-icon-button-color-background-tertiary-default-dark);
  color: var(--sk-action-icon-button-color-tertiary-default-dark);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--dark__DqpiO:enabled:hover {
  border-color: var(--sk-action-icon-button-color-border-tertiary-hover-dark);
  background-color: var(--sk-action-icon-button-color-background-tertiary-hover-dark);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--dark__DqpiO:enabled:focus-visible {
  background-color: var(--sk-action-icon-button-color-background-tertiary-focus-dark);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--dark__DqpiO:enabled:active {
  border-color: var(--sk-action-icon-button-color-border-tertiary-active-dark);
  background-color: var(--sk-action-icon-button-color-background-tertiary-active-dark);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--dark__DqpiO:disabled {
  border-color: var(--sk-action-icon-button-color-border-tertiary-disabled-dark);
  color: var(--sk-action-icon-button-color-tertiary-disabled-dark);
}

/**
 * Hack to display title attribute in mobile
 */

@media only screen and (max-width: 600px) {
  .sk-IconButton_main__Kzatv[title]:active::before {
    content: attr(title);
    display: inline-block;
    position: absolute;
    top: calc(var(--IconButton-top-position) - 0.4rem);
    left: 50%;
    width: -moz-fit-content;
    width: fit-content;
    padding: var(--IconButton-title-vertical-padding) 0.55rem;
    transform: translateX(-50%);
    border-radius: 2px;
    outline: solid 2px rgba(56, 56, 57, 0.8);
    background-color: rgb(56, 56, 57);
    color: var(--sk-color-white);
    font-size: var(--IconButton-title-font-size);
    white-space: nowrap;
  }
}

}
@layer ui {
  .sk-Avatar_main__vOWfc {
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-size-m);
  --sk-Avatar-size: var(--sk-data-display-avatar-size-m);

  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: var(--sk-Avatar-size);
  height: var(--sk-Avatar-size);
  overflow: hidden;
  text-align: center;
}

.sk-Avatar_main--fallback-icon__6hMe5 {
  background-color: var(--sk-color-grey-800);
  color: var(--sk-color-white);
}

.sk-Avatar_main--squared__HPuTd {
  border-radius: var(--sk-Avatar-border-radius);
}

.sk-Avatar_main--rounded__dp6R1 {
  border-radius: var(--sk-radius-rounded);
}

.sk-Avatar_main--size-m__4JSoT {
  --sk-Avatar-size: var(--sk-data-display-avatar-size-m);
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-radius-m);
}

.sk-Avatar_main--size-2xl__qhAOn {
  --sk-Avatar-size: var(--sk-data-display-avatar-size-2xl);
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-radius-2xl);
}

.sk-Avatar_main--size-4xl__Hxi5Q {
  --sk-Avatar-size: var(--sk-data-display-avatar-size-4xl);
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-radius-4xl);
}

}
@layer ui {
  .sk-SelectedOption_main__E_gDP {
  display: flex;
  flex-grow: 1;
  padding-right: var(--sk-space-4);
  overflow: hidden;
}

.sk-SelectedOption_SelectedOptionLabel__1A_FE {
  min-width: var(--sk-space-48);
  padding-right: var(--sk-space-4);
}

}
@layer ui {
  .sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_trigger__tmRSC {
  cursor: not-allowed;
}

.sk-SearchableSelect_dropdownContainer__D52vX {
  position: relative;
}

.sk-SearchableSelect_input__QRU9_ {
  width: 100%;
  border: none;
  background-color: transparent;
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
}

.sk-SearchableSelect_input__QRU9_:-webkit-autofill {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.sk-SearchableSelect_input__QRU9_:-webkit-autofill,
.sk-SearchableSelect_input__QRU9_:-webkit-autofill:hover,
.sk-SearchableSelect_input__QRU9_:-webkit-autofill:focus,
.sk-SearchableSelect_input__QRU9_:-webkit-autofill:active,
.sk-SearchableSelect_input__QRU9_:autofill {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_input__QRU9_ {
  cursor: not-allowed;
}

.sk-SearchableSelect_input__QRU9_:focus {
  outline: none;
}

.sk-SearchableSelect_input__QRU9_::-moz-placeholder {
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
}

.sk-SearchableSelect_input__QRU9_::placeholder {
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
}

.sk-SearchableSelect_trigger__tmRSC {
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  height: var(--sk-size-48);
  padding: var(--sk-space-16);
  border-width: var(--sk-form-select-field-size-border-width);
  border-style: solid;
  border-radius: var(--sk-form-searchable-select-field-radius);
  outline: unset;
  cursor: text;
  place-items: center;
  gap: var(--sk-space-8);
}

.sk-SearchableSelect_trigger__tmRSC .Placeholder {
  flex-grow: 1;
  padding-right: var(--sk-space-4);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-SearchableSelect_trigger__tmRSC .SelectedOptionLabel,
.sk-SearchableSelect_trigger__tmRSC .Placeholder {
  overflow: hidden;
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-SearchableSelect_rightIcon__PleOP {
  flex-grow: 0;
  flex-shrink: 0;
}

.sk-SearchableSelect_icon__tmdvd {
  flex-shrink: 0;
}

.sk-SearchableSelect_optionImageContainer__nZLOG {
  width: var(--sk-size-20);
  height: var(--sk-size-20);
}

.sk-SearchableSelect_optionImageContainer__nZLOG > * {
  border-radius: var(--sk-radius-4);
}

/* ON LIGHT BACKGROUND */

.sk-SearchableSelect_main--light__zgITV,
.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_input__QRU9_ {
  color: var(--sk-form-searchable-select-color-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_subLabel__xvHVh {
  color: var(--sk-form-searchable-select-secondary-label-color-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_message__N073R {
  color: var(--sk-form-searchable-select-message-color-default-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_message--error__movCz {
  color: var(--sk-form-searchable-select-message-color-error-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-default-light);
  background-color: var(--sk-form-searchable-select-field-color-background-default-light);
  color: var(--sk-form-searchable-select-field-color-default-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_input__QRU9_::-moz-placeholder {
  color: var(--sk-form-searchable-select-field-placeholder-color-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_input__QRU9_::placeholder {
  color: var(--sk-form-searchable-select-field-placeholder-color-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-read-only-light);
  background-color: var(--sk-form-searchable-select-field-color-background-read-only-light);
  color: var(--sk-form-searchable-select-field-color-read-only-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_icon__tmdvd {
  color: var(--sk-form-searchable-select-field-expand-icon-color-default-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_icon__tmdvd {
  color: var(--sk-form-searchable-select-field-expand-icon-color-read-only-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-error-default-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-error-focus-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:hover:not(:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-error-hover-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:active {
  border-color: var(--sk-form-searchable-select-field-color-border-error-active-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-read-only-focus-light);
  background-color: var(--sk-form-searchable-select-field-color-background-read-only-focus-light);
}

.sk-SearchableSelect_main--light__zgITV:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:hover {
  border-color: var(--sk-form-searchable-select-field-color-border-hover-light);
  background-color: var(--sk-form-searchable-select-field-color-background-hover-light);
}

.sk-SearchableSelect_main--light__zgITV:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:active {
  border-color: var(--sk-form-searchable-select-field-color-border-active-light);
  background-color: var(--sk-form-searchable-select-field-color-background-active-light);
}

.sk-SearchableSelect_main--light__zgITV:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-focus-light);
  background-color: var(--sk-form-searchable-select-field-color-background-focus-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_clearIcon__be5gE {
  color: var(--sk-form-searchable-select-field-clear-icon-color-light);
}

/* ON DARK BACKGROUND */

.sk-SearchableSelect_main--dark__Un2LI,
.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_input__QRU9_ {
  color: var(--sk-form-searchable-select-color-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_subLabel__xvHVh {
  color: var(--sk-form-searchable-select-secondary-label-color-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_message__N073R {
  color: var(--sk-form-searchable-select-message-color-default-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_message--error__movCz {
  color: var(--sk-form-searchable-select-message-color-error-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-default-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-default-dark);
  color: var(--sk-form-searchable-select-field-color-default-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_input__QRU9_::-moz-placeholder {
  color: var(--sk-form-searchable-select-field-placeholder-color-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_input__QRU9_::placeholder {
  color: var(--sk-form-searchable-select-field-placeholder-color-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-read-only-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-read-only-dark);
  color: var(--sk-form-searchable-select-field-color-read-only-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_icon__tmdvd {
  color: var(--sk-form-searchable-select-field-expand-icon-color-default-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_icon__tmdvd {
  color: var(--sk-form-searchable-select-field-expand-icon-color-read-only-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-error-default-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-error-focus-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:hover:not(:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-error-hover-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:active {
  border-color: var(--sk-form-searchable-select-field-color-border-error-active-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-read-only-focus-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-read-only-focus-dark);
}

.sk-SearchableSelect_main--dark__Un2LI:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:hover {
  border-color: var(--sk-form-searchable-select-field-color-border-hover-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-hover-dark);
}

.sk-SearchableSelect_main--dark__Un2LI:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:active {
  border-color: var(--sk-form-searchable-select-field-color-border-active-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-active-dark);
}

.sk-SearchableSelect_main--dark__Un2LI:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-focus-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-focus-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_clearIcon__be5gE {
  color: var(--sk-form-searchable-select-field-clear-icon-color-dark);
}

}
@layer ui {
  .sk-SearchableMultiSelect_main__pu6A3 {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
}

.sk-SearchableMultiSelect_optionTags__wyOO8 {
  display: flex;
  flex-wrap: wrap;
  gap: var(--sk-space-8);
}

}
@layer ui {
  .sk-Select_main--disabled__a6jig .sk-Select_trigger__K7tRY {
  cursor: not-allowed;
}

.sk-Select_dropdownContainer__UCrwh {
  position: relative;
}

.sk-Select_trigger__K7tRY {
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: flex-end;
  width: 100%;
  height: var(--sk-size-48);
  padding: var(--sk-space-16);
  border-width: var(--sk-form-select-field-size-border-width);
  border-style: solid;
  border-radius: var(--sk-form-select-field-radius);
  outline: unset;
  cursor: pointer;
  place-items: center;
}

.sk-Select_trigger__K7tRY .Placeholder {
  flex-grow: 1;
  padding-right: var(--sk-space-4);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-Select_trigger__K7tRY .SelectedOptionLabel,
.sk-Select_trigger__K7tRY .Placeholder {
  overflow: hidden;
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-Select_selectIcon___QndH {
  flex-grow: 0;
  flex-shrink: 0;
}

/* ON LIGHT BACKGROUND */

.sk-Select_main--light__ELftu {
  color: var(--sk-form-select-color-light);
}

.sk-Select_main--light__ELftu .SelectedOptionLabel {
  color: var(--sk-form-field-color-default-light);
}

.sk-Select_main--light__ELftu .sk-Select_subLabel__cvCLQ {
  color: var(--sk-form-select-secondary-label-color-light);
}

.sk-Select_main--light__ELftu .sk-Select_SelectedOptionSecondaryLabel__QYl6E {
  color: var(--sk-color-grey-600);
}

.sk-Select_main--light__ELftu .sk-Select_message__5VTU5 {
  color: var(--sk-form-select-message-color-default-light);
}

.sk-Select_main--light__ELftu .sk-Select_message--error__p6wwe {
  color: var(--sk-form-select-message-color-error-light);
}

.sk-Select_main--light__ELftu .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-default-light);
  background-color: var(--sk-form-select-field-color-background-default-light);
  color: var(--sk-form-select-field-color-default-light);
}

.sk-Select_main--light__ELftu .Placeholder {
  color: var(--sk-form-select-field-placeholder-color-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--disabled__a6jig .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-disabled-light);
  background-color: var(--sk-form-select-field-color-background-disabled-light);
  color: var(--sk-form-select-field-color-disabled-light);
}

.sk-Select_main--light__ELftu .sk-Select_icon__sX1k2 {
  color: var(--sk-form-select-field-expand-icon-color-default-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--disabled__a6jig .sk-Select_icon__sX1k2 {
  color: var(--sk-form-select-field-expand-icon-color-disabled-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-error-default-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-error-focus-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:hover:not(:focus-visible) {
  border-color: var(--sk-form-select-field-color-border-error-hover-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:active {
  border-color: var(--sk-form-select-field-color-border-error-active-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--disabled__a6jig .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-disabled-focus-light);
  background-color: var(--sk-form-select-field-color-background-disabled-focus-light);
}

.sk-Select_main--light__ELftu:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:hover {
  border-color: var(--sk-form-select-field-color-border-hover-light);
  background-color: var(--sk-form-select-field-color-background-hover-light);
}

.sk-Select_main--light__ELftu:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:active {
  border-color: var(--sk-form-select-field-color-border-active-light);
  background-color: var(--sk-form-select-field-color-background-active-light);
}

.sk-Select_main--light__ELftu:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-focus-light);
  background-color: var(--sk-form-select-field-color-background-focus-light);
}

/* ON DARK BACKGROUND */

.sk-Select_main--dark__Grzof {
  color: var(--sk-form-select-color-dark);
}

.sk-Select_main--dark__Grzof .SelectedOptionLabel {
  color: var(--sk-form-field-color-default-dark);
}

.sk-Select_main--dark__Grzof .sk-Select_SelectedOptionSecondaryLabel__QYl6E {
  color: var(--sk-color-grey-300);
}

.sk-Select_main--dark__Grzof .sk-Select_subLabel__cvCLQ {
  color: var(--sk-form-select-secondary-label-color-dark);
}

.sk-Select_main--dark__Grzof .sk-Select_message__5VTU5 {
  color: var(--sk-form-select-message-color-default-dark);
}

.sk-Select_main--dark__Grzof .sk-Select_message--error__p6wwe {
  color: var(--sk-form-select-message-color-error-dark);
}

.sk-Select_main--dark__Grzof .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-default-dark);
  background-color: var(--sk-form-select-field-color-background-default-dark);
  color: var(--sk-form-select-field-color-default-dark);
}

.sk-Select_main--dark__Grzof .Placeholder {
  color: var(--sk-form-select-field-placeholder-color-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--disabled__a6jig .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-disabled-dark);
  background-color: var(--sk-form-select-field-color-background-disabled-dark);
  color: var(--sk-form-select-field-color-disabled-dark);
}

.sk-Select_main--dark__Grzof .sk-Select_icon__sX1k2 {
  color: var(--sk-form-select-field-expand-icon-color-default-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--disabled__a6jig .sk-Select_icon__sX1k2 {
  color: var(--sk-form-select-field-expand-icon-color-disabled-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-error-default-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-error-focus-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:hover:not(:focus-visible) {
  border-color: var(--sk-form-select-field-color-border-error-hover-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:active {
  border-color: var(--sk-form-select-field-color-border-error-active-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--disabled__a6jig .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-disabled-focus-dark);
  background-color: var(--sk-form-select-field-color-background-disabled-focus-dark);
}

.sk-Select_main--dark__Grzof:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:hover {
  border-color: var(--sk-form-select-field-color-border-hover-dark);
  background-color: var(--sk-form-select-field-color-background-hover-dark);
}

.sk-Select_main--dark__Grzof:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:active {
  border-color: var(--sk-form-select-field-color-border-active-dark);
  background-color: var(--sk-form-select-field-color-background-active-dark);
}

.sk-Select_main--dark__Grzof:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-focus-dark);
  background-color: var(--sk-form-select-field-color-background-focus-dark);
}

}
